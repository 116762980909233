import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
import { DiscussionEmbed } from 'disqus-react'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../containers'

import styles from './blog-post.module.css'
import { AdPanel, SidebarGrid, SidebarPanel } from '../pages/index'
import Sidebar from './Sidebar'

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 600;
`

const CoverImage = styled(Img)`
  max-height: 600px;
`

const ArticleContainer = styled.article`
  max-width: 1000px;
  padding: 24px;
  margin: auto;
`

const ArticleHeader = styled(ContainerMain)`
  padding: 24px;
  margin: auto;
`

const ArticleInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const PublishedBy = styled.span`
  /* font-style: italic; */
  margin: 0 4px 0 0;
  /* font-size: 0.8rem; */
`

const AuthorItem = styled.span`
  margin: 0 24px 0 0;
  font-weight: 600;
`

const ArticleDate = styled.span`
  /* color: ${props => props.theme.theme.colors.blue}; */
  color: ${props => props.theme.theme.text.quarternary};
  margin: 0 24px 0 0;
`

const Category = styled.span`
  background-color: ${props => props.theme.theme.bg.inset};
  color: ${props => props.theme.theme.text.tertiary};
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 8px 0 0;
`

const ArticleBody = styled.div`
  font-family: 'Lora', serif;
  /* padding: 0 0 50px 0; */
  /* border-bottom: 2px solid ${props => props.theme.theme.border.secondary}; */

  h2{
    display: block;
    padding: 12px 12px 12px 0;
    margin: 24px 0 12px 0;
  }

  blockquote{
    margin: 0;
    padding: 12px;
    background-color: ${props => props.theme.theme.bg.inset};
    border-radius: 4px;
  }
`

const ArticleBodyContainer = styled.div`
  max-width: 700px;
  margin: 48px 0 48px 0;
`

function BlogPost (props) {
  const {_rawBody, authors, categories, title, mainImage, publishedAt, id} = props

  console.log(_rawBody)

  return (
    <>
      <ArticleHeader>
        <CoverImage
          fluid={mainImage.asset.fluid}
          objectFit="cover"
        />
        <Title>{title}</Title>

        <ArticleInfo>
          <PublishedBy>
            Written by
          </PublishedBy>
          {authors && authors.map((item, _key) => (
            <AuthorItem key={_key}>
              {item.author.name}
            </AuthorItem>
          ))}
            {publishedAt && (
            <ArticleDate>
              Last updated on {format(new Date(publishedAt), 'MMMM Do, YYYY')}
            </ArticleDate>
            )}

            {categories && categories.map(category => (
              <Category key={category._id}>{category.title}</Category>
            ))}
        </ArticleInfo>
      </ArticleHeader>

      <ContainerTwoCol>
        <ContainerContent>
          <ArticleBody>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </ArticleBody>
        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>


      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: id
              }}
          />
        </ContainerMain>
      </Backdrop>

      {/* <article className={styles.root}>
        {mainImage && mainImage.asset && (
          <div className={styles.mainImage}>
            <img
              src={imageUrlFor(buildImageObj(mainImage))
                .width(1200)
                .height(Math.floor((9 / 16) * 1200))
                .fit('crop')
                .auto('format')
                .url()}
              alt={mainImage.alt}
            />
          </div>
        )}
        <Container>
          <div className={styles.grid}>
            <div className={styles.mainContent}>
              <h1 className={styles.title}>{title}</h1>
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
            <aside className={styles.metaContent}>
              {publishedAt && (
                <div className={styles.publishedAt}>
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), 'MMMM Do, YYYY')}
                </div>
              )}
              {authors && <AuthorList items={authors} title='Authors' />}
              {categories && (
                <div className={styles.categories}>
                  <h3 className={styles.categoriesHeadline}>Categories</h3>
                  <ul>
                    {categories.map(category => (
                      <li key={category._id}>{category.title}</li>
                    ))}
                  </ul>
                </div>
              )}
            </aside>
          </div>
        </Container>
      </article> */}
    </>

  )
}

export default BlogPost
